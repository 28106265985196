import React from 'react';
import { Typography, Button, Box, CircularProgress } from '@mui/material';

const UpgradePrompt = ({ userData, onUpgrade }) => {
  if (!userData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4, p: 3, border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h5" gutterBottom>
        {userData.subscriptionStatus === 'active' ? 'Your Subscription' : 'Upgrade to Rizz Pro'}
      </Typography>
      {userData.subscriptionStatus === 'active' ? (
        <>
          <Typography variant="body1">
            Subscription Status: Active
          </Typography>
          <Typography variant="body1">
            Model Training Credits: {userData.credits?.modelTraining ?? 0}/1
          </Typography>
          <Typography variant="body1">
            Image Generation Credits: {userData.credits?.imageGeneration ?? 0}/100
          </Typography>
          <Typography variant="body1">
            Other Features Credits: {userData.credits?.otherFeatures ?? 0}/3000
          </Typography>
          <Typography variant="body1">
            Dating Bio Generator: Used {userData.usage?.datingBioGenerator ?? 0} times
          </Typography>
          <Typography variant="body1">
            Ice Breaker: Used {userData.usage?.iceBreaker ?? 0} times
          </Typography>
          <Typography variant="body1">
            Profile Rater: Used {userData.usage?.profileRater ?? 0} times
          </Typography>
          <Typography variant="body1">
            Reply Enhancer: Used {userData.usage?.replyEnhancer ?? 0} times
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" paragraph>
            Upgrade to Rizz Pro to unlock all features:
          </Typography>
          <ul>
            <li>3000 combined credits for Dating Bio Generator, Ice Breaker, Profile Rater, and Reply Enhancer</li>
            <li>1 model training credit for AI Dating Profile Generator</li>
            <li>100 image generation credits</li>
          </ul>
          <Button
            variant="contained"
            color="primary"
            onClick={onUpgrade}
            sx={{ mt: 2 }}
          >
            Upgrade Now
          </Button>
        </>
      )}
    </Box>
  );
};

export default UpgradePrompt;