import React, { useState, useEffect } from 'react';
import { Typography, Button, Box, Container, Grid, TextField, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import UpgradePrompt from './UpgradePrompt';

import tinder from '../assets/tinder-icon.png';
import bumble from '../assets/bumble-icon.png';
import hinge from '../assets/hinge-icon.png';
import okcupid from '../assets/okcupid-icon.png';
import badoo from '../assets/badoo-icon.png';

const AppIcon = styled('img')({
  width: 80,
  height: 80,
  cursor: 'pointer',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
});

const datingApps = [
  { name: 'Tinder', icon: tinder },
  { name: 'Bumble', icon: bumble },
  { name: 'Hinge', icon: hinge },
  { name: 'OkCupid', icon: okcupid },
  { name: 'Badoo', icon: badoo },
];

const appQuestions = {
  Tinder: [
    { id: 'funFact', label: 'What’s a fun fact or quirky thing about yourself that gets people talking?' },
    { id: 'weekendActivity', label: 'What’s your go-to weekend activity?' },
    { id: 'personality', label: 'Describe your personality in one sentence.' },
    { id: 'passion', label: 'What are you passionate about, and how do you pursue it?' },
  ],
  Bumble: [
    { id: 'proudOf', label: 'What’s something you’re proud of accomplishing recently?' },
    { id: 'makesSmile', label: 'What’s one thing that really makes you smile?' },
    { id: 'relationship', label: 'Describe your ideal relationship in three words.' },
    { id: 'unique', label: 'What’s something unique that people don’t usually expect about you?' },
  ],
  Hinge: [
    { id: 'passion', label: 'What’s a passion or hobby that you’re deeply invested in?' },
    { id: 'goodAt', label: 'What’s one thing you’re really good at and proud of?' },
    { id: 'timeOutsideWork', label: 'How do you spend your time outside of work?' },
    { id: 'idealWeekend', label: 'What does an ideal weekend look like for you?' },
  ],
  OkCupid: [
    { id: 'belief', label: 'What’s something you believe strongly in?' },
    { id: 'relationshipGoal', label: 'What kind of relationship are you seeking, and what values do you look for?' },
    { id: 'cause', label: 'What’s a cause or issue that you’re passionate about?' },
    { id: 'uniqueThing', label: 'Describe one thing that makes you unique.' },
  ],
  Badoo: [
    { id: 'interests', label: 'What are your main interests or hobbies?' },
    { id: 'lookingFor', label: 'What are you looking for in a potential match?' },
    { id: 'funFact', label: 'Share a fun fact about yourself.' },
    { id: 'idealDate', label: 'Describe your idea of a perfect first date.' },
  ],
};

const DatingBioGenerator = () => {
  const [selectedApp, setSelectedApp] = useState(null);
  const [answers, setAnswers] = useState({});
  const [generatedBio, setGeneratedBio] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/data`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data. Please try again.');
    }
  };

  const handleAppSelect = (app) => {
    setSelectedApp(app);
    setAnswers({});
    setGeneratedBio('');
  };

  const handleInputChange = (questionId, value) => {
    setAnswers(prev => ({ ...prev, [questionId]: value }));
  };

  const handleGenerateBio = async () => {
    if (!userData || userData.subscriptionStatus !== 'active') {
      setError('Please subscribe to use this feature.');
      return;
    }

    if (userData.credits.otherFeatures <= 0) {
      setError('You have used all your credits for this feature. Please upgrade your plan.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/generate-dating-bio`,
        { answers, selectedApp: selectedApp.name },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setGeneratedBio(response.data.bio);
      // Update local userData to reflect usage
      setUserData(prevData => ({
        ...prevData,
        credits: {
          ...prevData.credits,
          otherFeatures: prevData.credits.otherFeatures - 1
        },
        usage: {
          ...prevData.usage,
          datingBioGenerator: prevData.usage.datingBioGenerator + 1
        }
      }));
    } catch (err) {
      setError('Failed to generate bio. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom sx={{ mt: 4, fontWeight: 'bold', color: 'primary.main' }}>
        Dating Bio Generator
      </Typography>
      {userData && (
        <Typography variant="h6" align="center" gutterBottom>
          Credits used: {userData.usage?.datingBioGenerator ?? 0}
        </Typography>
      )}
      {userData && userData.credits?.otherFeatures <= 0 && (
        <UpgradePrompt userData={userData} onUpgrade={fetchUserData} />
      )}
      {!selectedApp ? (
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Select a Dating App
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {datingApps.map((app) => (
              <Grid item key={app.name}>
                <AppIcon
                  src={app.icon}
                  alt={app.name}
                  onClick={() => handleAppSelect(app)}
                />
                <Typography align="center">{app.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Generating Bio for {selectedApp.name}
          </Typography>
          {selectedApp && appQuestions[selectedApp.name].map(question => (
            <TextField
              key={question.id}
              fullWidth
              label={question.label}
              variant="outlined"
              margin="normal"
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              value={answers[question.id] || ''}
            />
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateBio}
            disabled={loading || Object.keys(answers).length !== appQuestions[selectedApp.name].length || !userData || userData.subscriptionStatus !== 'active' || userData.credits?.otherFeatures <= 0}
            sx={{ mt: 2 }}
          >
            {loading ? 'Generating...' : 'Generate Bio'}
          </Button>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          {loading && <CircularProgress sx={{ mt: 2 }} />}
          {generatedBio && (
            <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
              <Typography variant="h6" gutterBottom>Generated Bio:</Typography>
              <Typography>{generatedBio}</Typography>
            </Box>
          )}
          {userData && userData.subscriptionStatus !== 'active' && <UpgradePrompt userData={userData} onUpgrade={fetchUserData} />}
        </>
      )}
    </Container>
  );
};

export default DatingBioGenerator;