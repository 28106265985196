import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Container, Typography, Button, Box, CircularProgress, Grid, TextField, Chip, Alert, Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import UpgradePrompt from './UpgradePrompt';

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: '16px',
  padding: theme.spacing(6),
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(4),
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PreviewImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '200px',
  width: 'auto',
  height: 'auto',
  borderRadius: '8px',
  objectFit: 'contain',
});

const PreviewContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '16px',
  marginBottom: '16px',
});

const MessageReplyEnhancer = () => {
  const [userData, setUserData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [enhancedReply, setEnhancedReply] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [useImage, setUseImage] = useState(true);

  const messageTypes = ['Poetic', 'Sarcastic', 'Funny', 'Witty', 'Romantic', 'Roast'];

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/data`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data. Please try again.');
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
  });

  const uploadBoxStyle = useMemo(() => ({
    backgroundColor: isDragActive ? 'rgba(0, 0, 0, 0.1)' : 'transparent'
  }), [isDragActive]);

  const handleEnhanceReply = async () => {
    if ((useImage && !selectedFile) || (!useImage && !message) || !selectedType) {
      setError('Please provide the necessary information and select a reply type.');
      return;
    }

    if (!userData || userData.credits?.otherFeatures <= 0) {
      setError('You have no credits left. Please upgrade to continue.');
      return;
    }

    setLoading(true);
    setError('');

    const formData = new FormData();
    if (useImage) {
      formData.append('image', selectedFile);
    } else {
      formData.append('message', message);
    }
    formData.append('type', selectedType);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/enhance-reply`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setEnhancedReply(response.data.enhancedReply);
      fetchUserData(); // Fetch updated user data after successful enhancement
    } catch (error) {
      console.error('Error enhancing reply:', error);
      setError('Failed to enhance reply. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom sx={{ mt: 4, mb: 4, fontWeight: 'bold' }}>
        Message Reply Enhancer
      </Typography>
      {userData ? (
        <>
          <Typography variant="body2" align="right" sx={{ mb: 2 }}>
            Reply Enhancer used: {userData.usage?.replyEnhancer ?? 0} times
          </Typography>
          {userData.subscriptionStatus !== 'active' || userData.credits?.otherFeatures <= 0 ? (
            <UpgradePrompt userData={userData} onUpgrade={fetchUserData} />
          ) : (
            <>
              <Typography variant="body1" align="center" gutterBottom>
                Upload a photo or enter text, then select the type of reply you want to send.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <FormControlLabel
                  control={<Switch checked={useImage} onChange={() => setUseImage(!useImage)} />}
                  label={useImage ? "Using Image" : "Using Text"}
                />
              </Box>
              {useImage ? (
                <>
                  <UploadBox {...getRootProps()} style={uploadBoxStyle}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                    <Typography variant="h6" gutterBottom>
                      Drag & drop an image here
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      or
                    </Typography>
                    <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
                      Select File
                    </Button>
                  </UploadBox>
                  {selectedFile && (
                    <PreviewContainer>
                      <PreviewImage src={URL.createObjectURL(selectedFile)} alt="Uploaded" />
                    </PreviewContainer>
                  )}
                </>
              ) : (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter the message you received here"
                  sx={{ mt: 4, mb: 2 }}
                />
              )}
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Select reply type:
                </Typography>
                <Grid container spacing={1}>
                  {messageTypes.map((type) => (
                    <Grid item key={type}>
                      <Chip
                        label={type}
                        onClick={() => setSelectedType(type)}
                        color={selectedType === type ? 'primary' : 'default'}
                        variant={selectedType === type ? 'filled' : 'outlined'}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEnhanceReply}
                disabled={loading || (useImage && !selectedFile) || (!useImage && !message) || !selectedType}
                sx={{ mt: 2, display: 'block', margin: '0 auto', px: 4, py: 1 }}
              >
                Enhance Reply
              </Button>
              {loading && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <CircularProgress sx={{ mb: 2 }} />
                  <Typography variant="body1" color="textSecondary">
                    Enhancing your reply...
                  </Typography>
                </Box>
              )}
              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              {enhancedReply && (
                <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
                  <Typography variant="h6" gutterBottom>Enhanced Reply:</Typography>
                  <Typography>{enhancedReply}</Typography>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <Typography>Loading user data...</Typography>
      )}
    </Container>
  );
};

export default MessageReplyEnhancer;