// ImageGeneration.js
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Button, Box, CircularProgress, Paper, Grid, TextField, Alert, Select, MenuItem, Card, CardContent, CardMedia, CardActions, Divider, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { saveAs } from 'file-saver';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
  },
}));

const LargeCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '100%', // 1:1 aspect ratio
});

const ImageGeneration = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [generationPrompt, setGenerationPrompt] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [preGeneratedImages, setPreGeneratedImages] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [credits, setCredits] = useState(0);
  const [scale, setScale] = useState(1);
  const [seed, setSeed] = useState('');
  const [numImages, setNumImages] = useState(1);
  const [genderPreference, setGenderPreference] = useState('male');
  const [fetchingPreGenerated, setFetchingPreGenerated] = useState(false);

  const fetchUserModels = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fal-ai/user-models`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('Fetched user models:', response.data.models);
      setModels(response.data.models.map(model => ({
        ...model,
        url: model.s3Url || model.falUrl // Prefer S3 URL if available
      })));
      if (response.data.models.length > 0) {
        setSelectedModel(response.data.models[0].name);
        console.log('Selected model set to:', response.data.models[0].name);
      } else {
        console.log('No models available');
      }
    } catch (error) {
      console.error('Error fetching user models:', error);
      setError('Error fetching models. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserModels();
    setPreGeneratedImages(Array(20).fill({ url: 'https://via.placeholder.com/150' }));
  }, [fetchUserModels]);

  const generateImage = async () => {
    console.log('generateImage function called');
    
    const token = localStorage.getItem('token');
    console.log('Token retrieved:', token ? 'Token exists' : 'No token found');

    if (!selectedModel) {
      console.log('No model selected');
      setError('No valid trained model selected. Please select a model first.');
      return;
    }

    console.log('Proceeding with image generation');
    setGenerating(true);
    setError('');

    try {
      const selectedModelData = models.find(model => model.name === selectedModel);
      if (!selectedModelData || (!selectedModelData.s3Url && !selectedModelData.falUrl)) {
        throw new Error('Selected model does not have a valid URL');
      }
      const requestData = {
        prompt: generationPrompt,
        modelName: selectedModel,
        scale: scale,
        seed: seed ? parseInt(seed) : undefined,
        num_images: numImages
      };
      console.log('Sending request to backend:', requestData);
      
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/fal-ai/generate-image`, requestData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('Response received:', response.data);
      
      if (response.data.generatedImages && response.data.generatedImages.length > 0) {
        setGeneratedImages(response.data.generatedImages);
        console.log('Generated images set:', response.data.generatedImages.length);
      } else {
        throw new Error('No image data in the response');
      }
      
      if (response.data.remainingCredits !== undefined) {
        setCredits(response.data.remainingCredits);
        console.log('Remaining credits updated:', response.data.remainingCredits);
      }
    } catch (error) {
      console.error('Error generating image:', error);
      console.error('Error details:', error.response?.data);
      setError(error.response?.data?.message || 'Error generating image. Please try again.');
    } finally {
      setGenerating(false);
    }
  };

  const generatePreGeneratedImages = async () => {
    setFetchingPreGenerated(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/fal-ai/generate-pre-generated-images`, {
        numImages: 1,
        genderPreference: genderPreference,
        modelName: selectedModel
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.data.preGeneratedImages) {
        setPreGeneratedImages(response.data.preGeneratedImages);
      } else {
        throw new Error('No pre-generated image data in the response');
      }
      
      if (response.data.remainingCredits !== undefined) {
        setCredits(response.data.remainingCredits);
      }
    } catch (error) {
      console.error('Error generating pre-generated images:', error);
      setError(error.response?.data?.message || 'Error generating pre-generated images. Please try again.');
    } finally {
      setFetchingPreGenerated(false);
    }
  };

  const handleDownload = (imageUrl, fileName) => {
    saveAs(imageUrl, fileName);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Image Generation</Typography>
      
      {/* Model Selector */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Image Generation Settings</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="model-select-label">Select Model</InputLabel>
          <Select
            labelId="model-select-label"
            value={selectedModel}
            label="Select Model"
            onChange={(e) => {
              setSelectedModel(e.target.value);
              console.log('Selected model changed to:', e.target.value);
            }}
          >
            {models.map((model) => (
              <MenuItem key={model.name} value={model.name}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>

      <Grid container spacing={3}>
        {/* Generate Image Section */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Generate Custom Image</Typography>
            <TextField
              fullWidth
              label="Generation Prompt"
              variant="outlined"
              multiline
              rows={4}
              value={generationPrompt}
              onChange={(e) => setGenerationPrompt(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Scale"
                  type="number"
                  variant="outlined"
                  value={scale}
                  onChange={(e) => setScale(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Seed (optional)"
                  variant="outlined"
                  value={seed}
                  onChange={(e) => setSeed(e.target.value)}
                />
              </Grid>
            </Grid>
            <TextField
              fullWidth
              label="Number of Images"
              type="number"
              variant="outlined"
              value={numImages}
              onChange={(e) => setNumImages(Number(e.target.value))}
              sx={{ mt: 2, mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={generateImage}
              disabled={generating || !selectedModel}
              fullWidth
              sx={{ mt: 2 }}
            >
              {generating ? 'Generating...' : 'Generate Image'}
            </Button>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Paper>
        </Grid>

        {/* Generated Images Display */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Generated Images</Typography>
            {generating ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={3}>
                {generatedImages.map((image, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <StyledCard>
                      <LargeCardMedia
                        image={image.ownerAccessUrl || image.url} // Use ownerAccessUrl if available
                        title={`Generated ${index + 1}`}
                      />
                      <CardActions>
                        <Button 
                          size="small" 
                          color="primary" 
                          fullWidth
                          startIcon={<CloudDownloadIcon />}
                          onClick={() => handleDownload(image.ownerAccessUrl || image.url, `generated-image-${index + 1}.jpg`)}
                        >
                          Download
                        </Button>
                      </CardActions>
                    </StyledCard>
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>

        {/* Pre-generated Images Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Pre-generated Dating Profile Images</Typography>
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="gender-preference-label">Gender Preference</InputLabel>
                <Select
                  labelId="gender-preference-label"
                  value={genderPreference}
                  label="Gender Preference"
                  onChange={(e) => setGenderPreference(e.target.value)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              onClick={generatePreGeneratedImages}
              disabled={fetchingPreGenerated || !selectedModel}
              fullWidth
              sx={{ mb: 2 }}
            >
              {fetchingPreGenerated ? 'Generating...' : 'Generate Pre-defined Images'}
            </Button>
            {fetchingPreGenerated ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={3}>
                {preGeneratedImages.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <StyledCard>
                      <LargeCardMedia
                        image={image.ownerAccessUrl || image.url} // Use ownerAccessUrl if available
                        title={`Pre-generated ${index + 1}`}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {image.prompt}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button 
                          size="small" 
                          color="primary" 
                          fullWidth
                          startIcon={<CloudDownloadIcon />}
                          onClick={() => handleDownload(image.ownerAccessUrl || image.url, `pre-generated-image-${index + 1}.jpg`)}
                        >
                          Download
                        </Button>
                      </CardActions>
                    </StyledCard>
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImageGeneration;