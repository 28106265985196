import React, { useState, useCallback, useEffect } from 'react';
import { Container, Typography, Button, Box, CircularProgress, LinearProgress, Paper, Grid, IconButton, TextField, Alert, Card, CardContent, CardMedia, Divider, CardActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme?.palette?.primary?.main || '#1976d2'}`,
  borderRadius: '16px',
  padding: theme?.spacing?.(6) || '48px',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme?.spacing?.(4) || '32px',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme?.palette?.action?.hover || '#f5f5f5',
  },
}));

const PreviewContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  marginBottom: '16px',
  border: '2px solid #e0e0e0',
  borderRadius: '10px',
  overflow: 'hidden',
});

const PreviewImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
});

const DeleteButton = styled(IconButton)({
  position: 'absolute',
  top: 5,
  right: 5,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
});

const StyledList = styled('ul')({
  paddingLeft: '20px',
  '& li': {
    marginBottom: '8px',
  },
});

const SubscriptionStatus = ({ subscriptionStatus, credits }) => {
  return (
    <Box>
      <Typography variant="h6">Subscription Status: {subscriptionStatus}</Typography>
      <Typography>Model Training Credits: {credits.modelTraining}</Typography>
      <Typography>Image Generation Credits: {credits.imageGeneration}</Typography>
    </Box>
  );
};

const ModelTraining = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [modelName, setModelName] = useState('');
  const [modelTrainingCredits, setModelTrainingCredits] = useState(0);
  const [gender, setGender] = useState('');
  const [availableModels, setAvailableModels] = useState([]);
  const [logs, setLogs] = useState('');
  const [modelUrl, setModelUrl] = useState('');
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
    fetchAvailableModels();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/data`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setUserData(response.data);
      setModelTrainingCredits(response.data.credits.modelTraining);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Error fetching user data. Please try again.');
    }
  };

  const fetchAvailableModels = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fal-ai/user-models`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setAvailableModels(response.data.models);
    } catch (error) {
      console.error('Error fetching available models:', error);
      setError('Error fetching available models. Please try again.');
    }
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.files) {
      handleFiles(e.target.files);
    }
  }, []);

  const handleFiles = (files) => {
    const newFiles = Array.from(files);
    setSelectedFiles(prevFiles => {
      const updatedFiles = [...prevFiles, ...newFiles];
      if (updatedFiles.length > 100) {
        setError('Maximum 100 images allowed');
        return prevFiles;
      }
      return updatedFiles;
    });
  };

  const handleDelete = useCallback((index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  }, []);

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000; // 2 seconds

  const uploadAndProcess = async (retryCount = 0) => {
    if (!hasEnoughCredits()) {
      setError('You do not have enough credits to train a model. Please purchase more credits or upgrade your subscription.');
      return;
    }
    
    if (selectedFiles.length < 10) {
      setError('Please upload at least 10 images.');
      return;
    }
    if (selectedFiles.length > 100) {
      setError('Maximum 100 images allowed.');
      return;
    }
    setProcessing(true);
    setError('');
    setStatus('Uploading and processing...');

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('images', file);
    });
    formData.append('modelName', modelName);
    formData.append('gender', gender);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/fal-ai/upload-and-process`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
          setStatus(`Uploading: ${percentCompleted}%`);
        }
      });

      setStatus('Model training initiated successfully!');
      setModelTrainingCredits(prevCredits => prevCredits - 1);

      // Start polling for updates
      pollTrainingStatus(response.data.requestId);
    } catch (error) {
      console.error('Error during model training:', error);
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data.message;
        setError(errorMessage);
        setStatus('');
        setProcessing(false);
      } else if (error.response && error.response.data.message === 'Insufficient model training credits') {
        setError('You do not have enough credits to train a model. Please purchase more credits or upgrade your subscription.');
        setStatus('');
        setProcessing(false);
      } else if (retryCount < MAX_RETRIES) {
        setStatus(`An error occurred. Retrying... (Attempt ${retryCount + 1}/${MAX_RETRIES})`);
        setTimeout(() => uploadAndProcess(retryCount + 1), RETRY_DELAY);
      } else {
        setError('An error occurred during model training. Please try again later.');
        setStatus('');
        setProcessing(false);
      }
    }
  };

  const pollTrainingStatus = async (requestId) => {
    const pollInterval = setInterval(async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fal-ai/check-status/${requestId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const { status, falStatus, logs, progress, modelUrl, s3Url, ownerAccessUrl } = response.data;

        setStatus(`Training: ${status} (Fal AI: ${falStatus})`);
        setProgress(progress);
        setLogs(logs);

        if (status === 'completed' || status === 'failed') {
          clearInterval(pollInterval);
          setProcessing(false);
          if (status === 'completed') {
            setStatus('Model training completed successfully!');
            setModelUrl(s3Url || modelUrl); // Prefer S3 URL if available
            // You might want to store ownerAccessUrl as well if needed
          } else {
            setError('Model training failed. Please check the logs for details.');
          }
        }
      } catch (error) {
        console.error('Error polling model status:', error);
        clearInterval(pollInterval);
        setProcessing(false);
        setError('Error checking model status. Please check your models page for updates.');
      }
    }, 5000); // Poll every 5 seconds
  };

  const renderImagePreviews = () => (
    <Grid container spacing={2}>
      {selectedFiles.map((file, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <PreviewContainer>
            <PreviewImage src={URL.createObjectURL(file)} alt={`Preview ${index + 1}`} />
            <DeleteButton onClick={() => handleDelete(index)}>
              <DeleteIcon />
            </DeleteButton>
          </PreviewContainer>
        </Grid>
      ))}
    </Grid>
  );

  const renderGenderSelection = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>I am a:</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant={gender === 'man' ? 'contained' : 'outlined'}
            onClick={() => setGender('man')}
          >
            Man
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant={gender === 'woman' ? 'contained' : 'outlined'}
            onClick={() => setGender('woman')}
          >
            Woman
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const renderPhotoExamples = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>Photo Guidelines:</Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        Upload between 10 and 100 photos for best results.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>Ideal Photo Examples:</Typography>
          <StyledList>
            <li>High Definition and Good Lighting: Ensure your photos are in high resolution with clear and bright lighting.</li>
            <li>Various Facial Expressions: Showcase different emotions—smiling, serious, relaxed—to bring out different aspects of your personality.</li>
            <li>Diverse Locations and Outfits: Use a range of backgrounds and wear different clothes to add variety to your pictures.</li>
            <li>Variation of Backgrounds, Angles & Scenes: Mix up the angles, backgrounds, and scenes for an engaging gallery.</li>
          </StyledList>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>🚫 Photos to Avoid:</Typography>
          <StyledList>
            <li>Cutoff or Covered Faces: Your entire face should be visible without any cropping or obstructions.</li>
            <li>Multiple Persons or Blurry Images: Photos should only feature you, and be sharp and focused.</li>
            <li>Poorly Cropped or Features Not Visible: Avoid awkward cropping or anything that hides important facial features.</li>
            <li>Sunglasses or Hats: Keep your eyes and face visible—no sunglasses, hats, or anything covering your features.</li>
          </StyledList>
        </Grid>
      </Grid>
    </Box>
  );

  const renderAvailableModels = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>Your Available Models:</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model Name</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableModels.map((model, index) => (
              <TableRow key={index}>
                <TableCell>{model.name}</TableCell>
                <TableCell>{new Date(model.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{model.status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGeneratePictures(model.name, model.requestId)}
                    disabled={model.status.toLowerCase() !== 'completed'}
                  >
                    Create AI Dating Pictures
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const handleGeneratePictures = (modelName, requestId) => {
    navigate('/image-generation', { state: { selectedModel: modelName, requestId: requestId } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.credits.modelTraining <= 0) {
      setError('You do not have enough credits to train a model.');
      return;
    }
    // ... rest of the function ...
  };

  const handleModelTrainingSuccess = async () => {
    // ... existing success handling code ...
    await fetchUserData(); // Refetch user data to update credit count
  };

  const hasEnoughCredits = () => {
    return userData?.credits?.modelTraining > 0;
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Create Your AI Dating Profile
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ color: 'text.secondary' }}>
          Transform Your Photos into a Stunning AI-Generated Dating Profile
        </Typography>
      </Box>

      <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: 'primary.light', color: 'primary.contrastText' }}>
        <Typography variant="h5" gutterBottom>
          Your Model Training Credits: {userData?.credits?.modelTraining || 0}
        </Typography>
        <Typography variant="body1">
          {hasEnoughCredits() 
            ? "Use these credits to create unique AI models for your dating profile."
            : "You don't have any model training credits. Please upgrade your subscription or purchase credits to continue."}
        </Typography>
      </Paper>

      {userData && userData.credits.modelTraining === 0 && (
        <Alert severity="warning">
          You don't have any model training credits. Please upgrade your subscription or purchase credits to continue.
        </Alert>
      )}

      {availableModels.length > 0 && renderAvailableModels()}

      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'primary.main' }}>Train Your Custom AI Model</Typography>
        <Divider sx={{ mb: 4 }} />

        {renderGenderSelection()}
        {renderPhotoExamples()}

        <UploadBox
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <input
            type="file"
            multiple
            onChange={handleChange}
            style={{ display: 'none' }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              size="large"
            >
              Upload Your Photos
            </Button>
          </label>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Drag and drop 10-100 images here or click to select files
          </Typography>
        </UploadBox>

        {renderImagePreviews()}

        <Box sx={{ mt: 4 }}>
          <TextField
            fullWidth
            label="Give Your Model a Name"
            variant="outlined"
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
            sx={{ mb: 2 }}
          />
        </Box>

        <Tooltip title={!hasEnoughCredits() ? "You need more credits to create an AI model" : ""}>
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={uploadAndProcess}
              disabled={processing || selectedFiles.length === 0 || !modelName || !gender || !hasEnoughCredits()}
              sx={{ mt: 4, py: 2, fontSize: '1.2rem' }}
              fullWidth
            >
              {processing ? 'Creating Your AI Model...' : 'Create Your AI Model'}
            </Button>
          </span>
        </Tooltip>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {processing && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
              {status}
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default ModelTraining;