import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Grid, Paper, CircularProgress, Card, CardContent, CardActions, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UpgradePrompt from './UpgradePrompt';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LockIcon from '@mui/icons-material/Lock';

const Home = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Fetching user data with token:', token);
      if (!token) {
        console.error('No token found in localStorage');
        setLoading(false);
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/data`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('User data received:', response.data);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  const features = [
    { title: "AI Dating Profile Generator", color: "#E91E63", route: "/ai-dating-profile-generator", description: "Create stunning AI-generated dating profile pictures tailored to your personality.", isHot: true },
    { title: "Generate Ice Breakers", color: "#FF4081", route: "/ice-breaker", description: "Create witty and engaging conversation starters to break the ice and make a lasting impression." },
    { title: "Rate Your Profile", color: "#3F51B5", route: "/profile-rater", description: "Get AI-powered insights on your dating profile photos to maximize your chances of success." },
    { title: "Dating Bio Generator", color: "#009688", route: "/dating-bio-generator", description: "Create an attractive and engaging dating profile bio that stands out." },
    { title: "Reply Enhancer", color: "#FFC107", route: "/reply-enhancer", description: "Improve your responses and keep conversations flowing smoothly." }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 8, mb: 6, textAlign: 'center' }}>
        <Typography variant="h1" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#FF4081', fontSize: { xs: '3rem', md: '4rem' } }}>
          Welcome to Rizz App
        </Typography>
        <Typography variant="h5" color="text.secondary" paragraph sx={{ maxWidth: '800px', margin: '0 auto' }}>
          Elevate your dating game with AI-powered tools and insights
        </Typography>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UpgradePrompt userData={userData} onUpgrade={fetchUserData} />

          <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
            {features.map((item, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card elevation={3} sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  borderRadius: '16px', 
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.1)'
                  }
                }}>
                  <CardContent sx={{ flexGrow: 1, p: 4, position: 'relative' }}>
                    {item.isHot && (
                      <Chip
                        icon={<LocalFireDepartmentIcon />}
                        label="Hot & Exclusive"
                        color="error"
                        sx={{ position: 'absolute', top: 16, right: 16 }}
                      />
                    )}
                    <Typography variant="h4" component="h2" gutterBottom sx={{ color: item.color, fontWeight: 'bold' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {item.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 4, pt: 0 }}>
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      onClick={() => navigate(item.route)}
                      sx={{ 
                        borderRadius: '8px', 
                        py: 1.5, 
                        bgcolor: item.color, 
                        '&:hover': { 
                          bgcolor: item.color, 
                          opacity: 0.9,
                          transform: 'scale(1.05)',
                          transition: 'transform 0.2s ease-in-out'
                        } 
                      }}
                      startIcon={(!userData || userData.subscriptionStatus !== 'active') ? <LockIcon /> : null}
                    >
                      {index === 0 ? 'Try Now' : (index % 2 === 0 ? 'Start Generating' : 'Get Started')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Home;