import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ImageUpload from './ImageUpload';
import GenerateIcebreaker from './GenerateIcebreaker';
import UpgradePrompt from './UpgradePrompt';
import axios from 'axios';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  textAlign: 'center',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
}));

const IceBreaker = () => {
  const [extractedText, setExtractedText] = useState('');
  const [rizzLines, setRizzLines] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/data`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUserData(response.data);
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('Failed to fetch user data. Please try again.');
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleGenerateClick = async () => {
    if (!userData || userData.subscriptionStatus !== 'active') {
      setError('You need an active subscription to use this feature.');
      return;
    }

    setLoading(true);
    setError('');
    setRizzLines([]);
    try {
      if (!extractedText) {
        throw new Error('No text extracted from the image.');
      }
      console.log('Sending request with text:', extractedText);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/generate-icebreaker`,
        { extractedText },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log('Received response:', response.data);
      if (Array.isArray(response.data.rizzLine)) {
        setRizzLines(response.data.rizzLine);
        fetchUserData(); // Fetch updated user data after successful generation
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (err) {
      console.error('Full error object:', err);
      setError(err.response?.data?.message || err.message || 'Failed to generate icebreakers. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h2" align="center" gutterBottom sx={{ mt: 4, fontWeight: 'bold', color: 'primary.main' }}>
        Rizz App
      </Typography>
      <StyledPaper>
        {userData ? (
          <>
            <Typography variant="body2" align="right" sx={{ mb: 2 }}>
              Ice Breaker used: {userData.usage?.iceBreaker ?? 'N/A'} times
            </Typography>
            {userData.subscriptionStatus === 'active' ? (
              <>
                <ImageUpload setExtractedText={setExtractedText} />
                {extractedText && (
                  <Box sx={{ mt: 4 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      startIcon={<EmojiEmotionsIcon />}
                      onClick={handleGenerateClick}
                      disabled={loading}
                      sx={{ borderRadius: '25px', padding: '10px 30px' }}
                    >
                      {loading ? 'Generating...' : 'Generate Rizz'}
                    </Button>
                  </Box>
                )}
                {error && (
                  <Typography color="error" sx={{ mt: 2 }}>
                    {error}
                  </Typography>
                )}
                {rizzLines && Array.isArray(rizzLines) && rizzLines.length > 0 && <GenerateIcebreaker rizzLines={rizzLines} />}
              </>
            ) : (
              <UpgradePrompt userData={userData} onUpgrade={fetchUserData} />
            )}
          </>
        ) : (
          <Typography>Loading user data...</Typography>
        )}
      </StyledPaper>
    </Container>
  );
};

export default IceBreaker;